<template>	
	<!-- 그리드 슬라이드 -->
	<div :class="['slide-wrap', 'grid', Number(invitation.invitationGalleryTypeArray) === 2?'array2':'array3', invitation.invitationGalleryComplexMoreYn==='Y'?'img-more':'']" :key="invitation.changeKey">
		<!--
			** 배열이 2단일 경우 : grid-list가 2개 array2 class 추가
			** 배열이 3단일 경우 : grid-list가 3개 array3 class 추가
			** 사진비율 복합일 경우 img에 long or short class 랜덤으로 추가 부탁드립니다.
			** 사진비율 직사각형 일 경우 img에 long class 추가
			** 사진 더보기 버튼이 보여야 할 경우 more class 추가
		//  -->
		{{invitation.changeKey}}
		<div class="hidden-box">
			<template v-if="invitation.invitationGalleryImagesFile!==undefined && invitation.invitationGalleryImagesFile!==null && invitation.invitationGalleryImagesFile.length>0">
				<template v-if="Number(invitation.invitationGalleryTypeArray) === 2">
					<div class="grid-list" >
						<template v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile">
								<p v-if="(index)%2 === 0" :key="index" :class="['img', pictureRation(invitationGalleryImageFile)]" @click="clickEmagePop(index)"><img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="" loading="lazy"></p>
						</template>
					</div>			
					<div class="grid-list" >
						<template v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile">
								<p v-if="(index)%2 === 1" :key="index" :class="['img', pictureRation(invitationGalleryImageFile)]" @click="clickEmagePop(index)"><img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="" loading="lazy"></p>
						</template>
					</div>	
				</template>
				<template v-if="Number(invitation.invitationGalleryTypeArray) === 3">
					<div class="grid-list" >
						<template v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile">
								<p v-if="(index)%3 === 0" :key="index" :class="['img', pictureRation(invitationGalleryImageFile)]" @click="clickEmagePop(index)"><img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="" loading="lazy"></p>
						</template>
					</div>			
					<div class="grid-list" >
						<template v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile">
								<p v-if="(index)%3 === 1" :key="index" :class="['img', pictureRation(invitationGalleryImageFile)]" @click="clickEmagePop(index)"><img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="" loading="lazy"></p>
						</template>
					</div>			
					<div class="grid-list" >
						<template v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile">
								<p v-if="(index)%3 === 2" :key="index" :class="['img', pictureRation(invitationGalleryImageFile)]" @click="clickEmagePop(index)"><img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="" loading="lazy"></p>
						</template>
					</div>	
				</template>
			</template>
			<template v-else>
				<template v-if="Number(invitation.invitationGalleryTypeArray) === 2">
					<div class="grid-list">
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
					</div>
					<div class="grid-list">
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
					</div>
				</template>
				<template v-if="Number(invitation.invitationGalleryTypeArray) === 3">
					<div class="grid-list">
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
					</div>
					<div class="grid-list">
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
					</div>
					<div class="grid-list">
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img short"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
						<p class="img long"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></p>
					</div>
				</template>
			</template>
		</div>
		<div class="more">
			<a href="javascript:" class="more-btn"><span>사진 더보기</span></a>
		</div>
	</div>
	<!-- //  그리드 슬라이드 -->
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WritePreviewGalleryGride',
  props:['invitation'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  beforeDestroy(){
  },
  methods: {
	pictureRation(invitationGalleryImageFile){
		if(this.invitation.invitationGalleryTypeRatio !== 'square')
			if(this.invitation.invitationGalleryTypeRatio === 'complex'){
				return invitationGalleryImageFile.fileValue1
			}else
				return this.invitation.invitationGalleryTypeRatio
		else
			return ''
	},
	clickEmagePop(index){
		const param = {
			flag: 'imageExtendsViewPop',
			index:index,
			files:this.invitation.invitationGalleryBeforeImagesFile
		}
		EventBus.$emit('layerPopup',param)
	}

  },

}
</script>
